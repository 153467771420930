<template>
    <div>
        <h3>Configuration de la compétition</h3>
        <fieldset class="fieldset">
            <legend>Général</legend>
            <b-form-group
                label="Type">
                <b-form-select
                    v-model="competitionType"
                    :options = "listOfCompetitionTypes"
                    @change = "competitionType_Changed"
                />
            </b-form-group>
            <b-form-group
                label="Emplacement"
                v-if="!isFederal">
                <b-form-input type="text" v-model="location" class="location" />
            </b-form-group>
            <b-form-group
                label="Nom de la compétition"
                v-if="!isFederal">
                    <b-form-input type="text" v-model="name"/>
            </b-form-group>

            <info-helper v-if="userHasRole(Roles.Tester)">
                <b-checkbox switch v-model="isSimulation" disabled="true">Est pour simulation uniquement</b-checkbox>
                <template #info-text>Vous avez activé le mode test (voir configuration).<br>
                En mode test, vous ne pouvez créer que des "fausses" compétitions, pour test.
                </template>
            </info-helper>

        </fieldset>
        <fieldset class="fieldset" v-if="isPartOfCdfCva">
            <legend>{{ selectedCompetitionType.longname }}</legend>
            <b-radio-group v-model="scope" @change="scope_Changed">
                <b-radio value="nation">Finale</b-radio>
                <b-radio value="region">Manche(s) sélective(s)</b-radio>
            </b-radio-group>
            <b-form-group
                label="Année/saison">
                <b-form-input type="text" v-model="year" :disabled="true"/>
                <span v-if="isSelective">comptant pour le CdF {{ season.yearSelectiveCdF }}.</span>
            </b-form-group>
            <b-form-group
                label='Est "Open"'>
                <b-form-checkbox v-model="isOpen" />
            </b-form-group>
            <b-form-group
                label="Lieu">
                <b-form-input type="text" class="location" v-model="location" @change="setDefaultName" />
            </b-form-group>
            <b-form-group
                label="Nom de la compétition">
                <b-form-input type="text" v-model="name" @change="name_Changed"/>
            </b-form-group>
        </fieldset>

        <fieldset class="fieldset">
            <legend>Niveau(x)</legend>
            <b-form-checkbox-group
                label="Niveaux"
                v-model="selectedLevels"
                >
                <b-form-checkbox v-for="level in levels" :key="level.code" :value="level">{{ level.name }}</b-form-checkbox>
            </b-form-checkbox-group>
        </fieldset>

        <fieldset class="fieldset multi-columns">
            <legend>Manches</legend>
            <b-form-group label="Nombre de manches">
                 <number-of-rounds-editor
                  v-model="nb_rounds"
                  :minRoundsToKeep="nb_min_rounds"
                  :canRemoveRound="true"
                  :canAddRound="true"
                  @addRound="addRound"
                  @removeRound="removeRound"
                 >
                 </number-of-rounds-editor>
            </b-form-group>

            <b-form-group label="Règle de calcul des résultats">
                <b-select v-model="roundAverageComputationRule" size="sm" :disabled="nb_rounds <= 1">
                    <b-select-option value="averageOfAllRounds">Moyenne de toutes les manches</b-select-option>
                    <b-select-option value="averageOfNBestRounds">Moyenne des N meilleures manches de chaque pilote (overall)</b-select-option>
                    <b-select-option value="averageExcludingNWorseRounds">Moyenne des manches à l'exclusion des N moins bonnes manches de chaque pilote (overall)</b-select-option>
                </b-select>
            </b-form-group>

            <b-form-group
                :label="nbRoundToSkipOrKeepLabel"
                :disabled="!nbRoundToSkipOrKeepVisible || nb_rounds <= 1">
                <!--<b-form-input type="number" class="nbRounds" v-model="nbRoundToSkipOrKeep" />-->
                <number-of-rounds-editor
                  v-model="nbRoundToSkipOrKeep"
                  :minRoundsToKeep="1"
                  :maxRounds="nb_rounds-1"
                  :canRemoveRound="true"
                  :canAddRound="true"
                  @addRound="addKeepableRounds"
                  @removeRound="removeKeepableRounds"
                />
            </b-form-group>
            <br/>
         </fieldset>

        <b-button
            variant="primary"
            @click="createCompetition_Click"
        >Valider la création
        </b-button>
    </div>
</template>

<script>
import { REF_COMPETITION_TYPE, SEASON, SEASON_COMPETITION_TYPE_CATEGORY_LEVEL, REF_LEVEL, REF_CATEGORY, DATA_SYNC_SUBSCRIPTIONS /*RST_COMPETITION, RST_ROUND, RST_LEVEL, RST_CATEGORY*/ } from '@/LocalDB/index.js'
import { /*mapMutations,*/ mapGetters } from "vuex";
import ModelViewsHelper from "@/services/store/models/ModelViews";
import NumberOfRoundsEditor from '@/components/NumberOfRoundsEditor.vue';
import CompetitionsConfigurationHelper from "@/services/helpers/competitionsConfigurationHelper";
import InfoHelper from '@/components/InfoHelper.vue';

export default {
  components: { NumberOfRoundsEditor, InfoHelper },
    data(){
        return {
            id: null,
            name: '',
            scope: 'nation',
            isOpen: false,
            location: '',
            competitionType: '',
            year: '',
            isSimulation: false,
            nameSet: false,
            disabledHidden: false,
            nb_rounds: 1,
            nb_min_rounds: 1,
            roundAverageComputationRule: 'averageOfAllRounds',
            nbRoundToSkipOrKeep: 1,
            selectedLevels: [],
        }
    },
    computed:{
        isFederal(){
            if(this.selectedCompetitionType == null)
                return false;
            return this.selectedCompetitionType.isFederal;
        },
        isCva(){
            if(this.selectedCompetitionType == null)
                return false;
            return this.selectedCompetitionType.isCva;
        },
        isPartOfCdfCva(){
            if(this.selectedCompetitionType == null)
                return false;
            return this.selectedCompetitionType.isPartOfCdfCva;
        },
        isSelective(){
            return this.isPartOfCdfCva && (this.scope == "region");
        },
        season(){
            return SEASON.query().where("year", this.year).get()[0];
        },

        listOfCompetitionTypes(){
            var flist = REF_COMPETITION_TYPE.query().where(ct => !ct.isArchived).where('isCva', true).get();
            var rlist = flist.map((ct) => { var r = {value: ct.code, text: ct.name}; return r;});
            return rlist;
        },
        selectedCompetitionType(){
            var sel = REF_COMPETITION_TYPE.query().where(ct => ct.code == this.competitionType).get()[0];
            return sel;
        },
        nbRoundToSkipOrKeepVisible(){
            return this.roundAverageComputationRule != "averageOfAllRounds";
        },
        nbRoundToSkipOrKeepLabel(){
            if(this.roundAverageComputationRule == "averageOfNBestRounds")
                return "Nb. manches conservées";
            if(this.roundAverageComputationRule == "averageExcludingNWorseRounds")
                return "Nb. manches exclues";
            return "Nb. manches considérées";
        },
        levels(){
            var ret=[];
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
                .with(`LEVEL`)
                .where('year', this.year)
                .where('competition_type', this.competitionType)
                .get()
                .map(item => item.level)
                .filter((value, index, self) => { return self.indexOf(value) === index})
                .forEach(code => {
                    ret.push(REF_LEVEL.query().where('code', code).get()[0]);
                });
            return ret;
        },
        nbRounds: {
            get: function(){ return this.nb_Rounds; },
            set: function(value) { this.nb_Rounds = value; }
        },
    },
    methods:{
        ...mapGetters('modelViewsHelper', ['getDetailedStaffListItems'] ),

        // Interraction sur modification (sélection dans la liste) du type de compéitition
        competitionType_Changed(){
            if(this.isFederal && this.isCva){
                this.isPartOfCdf = true;
                this.scope = 'region';
                this.year = new Date().getFullYear();
                this.setDefaultName();
                this.step = 2;
            }
        },

        // Interraction sur modification (choix du bouton radio) de la portée de la compétition
        scope_Changed(){
            this.setDefaultName();
            this.staff = ModelViewsHelper.getDetailedStaffListItems(this.competitionType, this.scope,this.year, null);
        },

        year_Changed(){
            this.setDefaultName();
            this.staff = ModelViewsHelper.getDetailedStaffListItems(this.competitionType, this.scope,this.year, null);
        },

        // Interraction sur modification (saisie) du nom de la compétition
        name_Changed(){
            this.nameSet = (this.name!="");
            this.setDefaultName();
        },

        selectedLevelsCategory(levelCode){
            var ret = [];
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
                .where('year', this.year)
                .where('competition_type', this.competitionType)
                .where('level', levelCode)
                .get()
                .map(item => item.category)
                .filter((value, index, self) => { return self.indexOf(value) === index})
                .forEach(code => {
                    ret.push(REF_CATEGORY.query().where('code', code).get()[0]);
                });
            return ret;
        },

        createCompetition_Click(){
            this.$showLoader()
              .then(() => {
                var newCompetition = CompetitionsConfigurationHelper.createCompetition(
                    this.competitionType,
                    this.year,
                    this.scope,
                    this.name,
                    this.location,
                    this.isOpen,
                    this.isSimulation,
                    this.nb_rounds,
                    this.roundAverageComputationRule,
                    this.nbRoundToSkipOrKeep,
                    this.selectedLevels,
                );

                this.setCurrentCompetition_id(newCompetition.id);
                DATA_SYNC_SUBSCRIPTIONS.insert({ data: { type: 'RST_COMPETITION', id: newCompetition.id}});
                this.$router.push({name: 'CompetitionEdit'});
            });
        },

        setDefaultName(){
            if(this.isPartOfCdfCva && !this.nameSet){
                if(this.isSelective === true){
                    this.name = (this.nb_rounds == 1)
                              ? "Manche sélective"
                              : "Manches sélectives"
                    if(this.location !== ''){
                        this.name += ' de ' + this.location;
                    }
                }else{
                    this.name = 'Finale du CdF (CVA) ' + this.season.yearFinalCdF;
                }
            }
        },

        init(){
            this.competitionType = this.listOfCompetitionTypes[0].value;
            this.year = new Date().getFullYear();
            this.scope = "nation";
            this.setDefaultName();
            this.$hideLoader();
        },
        addRound(){
          this.nb_rounds++;
          this.setDefaultName();
        },
        removeRound(){
          this.nb_rounds--;
          this.setDefaultName();
        },
        removeKeepableRounds(){
          this.nbRoundToSkipOrKeep--;
        },
        addKeepableRounds(){
            this.nbRoundToSkipOrKeep++;
        },
    },
    mounted(){
        this.isSimulation = this.userHasRole(this.Roles.Tester);
        if(this.currentCompetition_id != null){
          this.$showLoader()
            .then(() => {
              this.$router.push({ name: 'CompetitionsRunning'});
          })
          return;
        }
        this.checkSynchro();
        this.init();
        this.$hideLoader();
    }
}
</script>

<style scoped>
    .location.selective { font-weight:bolder; font-size:1.4rem; }
    .roundNo { font-weight: bolder; font-size: 1.2rem; }
    .level { font-weight: bolder; margin-top: 1.2rem; margin-bottom:0.5rem; }
    .category { min-width:100px; max-width:100px; border-width:1px; border-style:solid; padding:2px; margin: 5px; border-radius: 5px;}
    .category.actif{ border-color: orangered ;}
    .category.inactif{ border-color: darkgray; }
    .inactif { color: darkgray; cursor: default; }
    .categories-list{
        display:flex;
        align-content: space-around;
        justify-content:center;
        flex-wrap: wrap;
    }
    .completed{ border:green 1px solid; background-color:lightgreen;}
    .container{ display:grid;
        grid-template-columns: 40% 60%;
    }
    .col { margin-top: 7px; margin-bottom: 7px;}
    .col.label { grid-column: 1; text-align:right; padding-right: 2px; /*font-size:0.7em;*/  }
    .col.value { grid-column: 2; text-align:left; padding-left: 0px; /*font-size:1.2em;*/ }
    .colspan { grid-column: 1 / 3 !important; }
    .col > input[type='text'] { width: 100%; }
    .nbRounds { width:30%; }
    .actors-list-container{ display: flex; justify-content: center; flex-wrap: wrap; margin-top: 15px; width:100% }

   @media screen and (max-width: 767px) and (orientation: portrait) {
       .actors-list-container{display: flex; justify-content: flex-start; flex-wrap: wrap; margin-top: 15px; margin-left:10px; margin-right: 10px;font-size: 0.9rem; }
   }
    div.cdf{ margin-top: 5px;}
    fieldset.fieldset{margin-bottom: 2rem;}
</style>

<style>
</style>
